import classes from "./TheHeader.module.scss";
import {useTelegram} from "../../hooks/useTelegram";
import logo from "../../static/images/logo.png"

const TheHeader = () => {
    const { webApp, user, lang   } = useTelegram();
    const menu = [
        {
            id: 0,
            name: 'Услуги',
            hash: '#offers',
        },
        {
            id: 1,
            name: 'Портфолио',
            hash: '#portfolio',
        },
        {
            id: 2,
            name: 'Преимущества',
            hash: '#advantages',
        },
        {
            id: 3,
            name: 'Контакты',
            hash: '#contacts',
        },
    ];
    return (
        <div className={classes.header}>
            <img src={logo} className={classes.logo}></img>
            <ul className={classes.menu}>
                {menu.map(item =>
                    (item.id !== 3 || !user) && (
                        <li
                            key={item.id}
                            className={`${classes.item} ${item.id === 3 && classes.contacts}`}
                        >
                            <a href={item.hash} className={classes.link}>
                                { item.name }
                            </a>
                        </li>
                    )
                )}
            </ul>
        </div>
    );
}

export default TheHeader;
