import classes from "./WorkWithUs.module.scss";
import { accordionItems } from "../../assets/constants/consts";
import Accordion from "../Accordion/Accordion";

const WorkWithUs = () => {
    return(
        <div className={classes.WorkWithUs}>
            <h2 className={classes.heading}>
                Работа <span>с нами</span>:
            </h2>

            <Accordion items={accordionItems}/>
        </div>
    );
}

export default WorkWithUs;
