import classes from "./Cases.module.scss";
import {Swiper, SwiperSlide} from "swiper/react";
import { Navigation } from "swiper/modules";
import ArrowLeftIcon from "../ArrowLeftIcon/ArrowLeftIcon";
import ArrowRightIcon from "../ArrowRightIcon/ArrowRightIcon";
import cases1 from "../../static/images/cases1.png";
import cases2 from "../../static/images/cases2.png";
import cases3 from "../../static/images/cases3.png";
import cases4 from "../../static/images/cases4.png";
import cases5 from "../../static/images/cases5.png";
import cases6 from "../../static/images/cases6.png";
import cases7 from "../../static/images/cases7.png";
import cases8 from "../../static/images/cases8.png";
import cases9 from "../../static/images/cases9.png";
import cases10 from "../../static/images/cases10.png";
import cases11 from "../../static/images/cases11.png";
const Cases = () => {
    const imageCases = [
        {id:1, image: cases6},
        {id:2, image: cases2},
        {id:3, image: cases1},
        {id:4, image: cases8},
        {id:5, image: cases5},
        {id:6, image: cases3},
        {id:7, image: cases7},
        {id:8, image: cases4},
        {id:9, image: cases9},
        {id:10, image: cases10},
        {id:11, image: cases11},
    ]

    return (
        <div className={classes.Cases} id="portfolio">
            <h3 className={classes.heading}>
                Кейсы
            </h3>
            <Swiper
                modules={[Navigation]}
                loop={true}
                spaceBetween={20}
                slidesPerGroup={1}
                className={classes.swiperWrap}
                navigation={{ // Опции навигации
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }}
                breakpoints={{
                    0: {
                        slidesPerView: 1.4,
                        spaceBetween: 30
                    },

                    768: {
                        slidesPerView: 4,
                        spaceBetween: 40
                    },
                }}
            >
                {imageCases.map(item =>
                    <SwiperSlide
                        className={classes.slide}
                        style={{backgroundImage: `url(${item.image})`,
                                backgroundSize: 'contain',
                                backgroundPosition: 'center'}}
                        key={item.id}
                    >
                        {/*<img src={item.image} className={classes.slideImage}/>*/}
                        {/*<div className={classes.iphone}> Slide 1 </div>*/}
                    </SwiperSlide>
                )}
                {imageCases.map(item =>
                    <SwiperSlide
                        className={classes.slide}
                        style={{backgroundImage: `url(${item.image})`,
                            backgroundSize: 'contain',
                            backgroundPosition: 'center'}}
                        key={item.id}
                    >
                    </SwiperSlide>
                )}
            </Swiper>
            <div className={classes.navigation}>
                <button className={`${classes.roundedBtn} swiper-button-prev`}>
                    <ArrowLeftIcon className={classes.icon}/>
                </button>
                <button className={`${classes.roundedBtn} swiper-button-next`}>
                    <ArrowRightIcon className={classes.icon}/>
                </button>
            </div>
        </div>
    );
}

export default Cases;
