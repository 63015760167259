const server_api = 'https://api.proself.group/';
export async function track_newUser(name,telegram,id_telegram){
    let user = {
        name:name,
        telegram:telegram ? telegram : 'Нет Юзернейма',
        id_telegram:id_telegram
    };
    let response = await fetch(server_api+'tracking1', {
        method:'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
    });
    return await response.json()
}
export async function track_userSendMessage(id_telegram){
    let user = {
        id_telegram:id_telegram
    };
    let response = await fetch(server_api+'tracking2', {
        method:'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
    });
    return await response.json()
}