import React, {useEffect, useRef} from 'react';
import classes from "./AdvantagesBlock.module.scss";
import Advantages from "./Advantages/Advantages";
import AdvantagesUser from "./AdvantagesUser/AdvantagesUser";

const AdvantagesBlock = () => {
    const reFon1 = useRef();
    const reFon2 = useRef();

    useEffect(()=>{
        if(window.innerWidth > 768) {
            window.addEventListener('mousemove', function (e) {
                let x = e.clientX / window.innerWidth;
                let y = e.clientY / window.innerHeight;
                if (reFon1.current)
                    reFon1.current.style.transform = 'translate(' + x * 200 + 'px, -' + y * 50 + 'px)'
                if (reFon2.current)
                    reFon2.current.style.transform = 'translate(-' + x * 400 + 'px, -' + y * 100 + 'px)'
            });
        }
    },[])

    return (
        <div className={classes._} id="advantages">
            <div className={classes.fonBlock}>
                <div ref={reFon1} className={classes.fon1}></div>
                <div ref={reFon2} className={classes.fon2}></div>
            </div>
            <div className={classes.block}>
                <Advantages/>
                <AdvantagesUser/>
            </div>
        </div>
    );
};

export default AdvantagesBlock;
