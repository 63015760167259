import classes from "./hero.module.scss";
import {useEffect, useRef} from "react";
import gsap from 'gsap';

const Hero = () => {
    const reFon1 = useRef();
    const reFon2 = useRef();
    const reHeading = useRef();
    const reDescription = useRef();

    useEffect(()=>{
        if(window.innerWidth > 768){
            window.addEventListener('mousemove', function(e) {
                let x = e.clientX / window.innerWidth;
                let y = e.clientY / window.innerHeight;
                if(reFon1.current)
                    reFon1.current.style.transform = 'translate(' + x * 200 + 'px, -' + y * 200 + 'px)'
                if(reFon2.current)
                    reFon2.current.style.transform = 'translate(' + x * 200 + 'px, -' + y * 200 + 'px)'
            });
        }
        if(window.innerWidth > 768) {
            if (reHeading.current && reDescription.current) {
                gsap.to(reHeading.current, {duration: .7, y: '0%', opacity: 1, delay: .3});
                gsap.to(reDescription.current, {duration: .7, y: '0%', opacity: 1, delay: .7});
            }
        }
    },[])

    return (
        <div className={classes.hero}>
            <div className={classes.fonBlock}>
                <div
                    ref={reFon1}
                    className={classes.fon1}
                >
                </div>
                <div
                    ref={reFon2}
                    className={classes.fon2}
                >
                </div>
            </div>
            <h1
                ref={reHeading}
                className={classes.heading}
            >
                Комплексные IT-решения <br/><span>в телеграмм</span>
            </h1>
            <p
                ref={reDescription}
                className={classes.description}
            >
                Разрабатываем индивидуальные высококачественные веб-сервисы и&nbsp;приложения
            </p>
        </div>
    );
}

export default Hero;
