import classes from "./TheFooter.module.scss";
import TelegramIcon from "./TelegramIcon/TelegramIcon";
import InstagramIcon from "./InstagramIcon/InstagramIcon";
import WhatsUpIcon from "./WhatsUpIcon/WhatsUpIcon";

const TheFooter = () => {
    const contacts = {
        tel: '+7 960 896 90 44',
        email: 'support@proself.group',
        telegram: 'proself',
        whatsup: '+79608969044',
        instagram: 'proself.group'
    }

    return(
        <footer className={classes.TheFooter} id="contacts">
            <div className={classes.logo}>
                Комплексные IT-решения
            </div>
            <div className={classes.contacts}>
                <a
                    href={'tel:'+contacts.tel.split(' ').join('')}
                >
                    {contacts.tel}
                </a>
                <a
                    href={"mailto:"+contacts.email}
                >
                    {contacts.email}
                </a>
            </div>
            <div className={classes.social}>
                <TelegramIcon style={{cursor:'pointer'}} onClick={()=>window.open('https://t.me/'+contacts.telegram, '_blank')}/>
                <WhatsUpIcon style={{cursor:'pointer'}} onClick={()=>window.open('https://wa.me/'+contacts.whatsup, '_blank')}/>
                <InstagramIcon style={{cursor:'pointer'}} onClick={()=>window.open('https://www.instagram.com/'+contacts.instagram, '_blank')}/>
            </div>
        </footer>
    )
}

export default TheFooter;
