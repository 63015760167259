import accordion_1 from "../../static/images/accordion_1.png";
import accordion_2 from "../../static/images/accordion_2.png";
import accordion_3 from "../../static/images/accordion_3.png";
import accordion_4 from "../../static/images/accordion_4.png";
import accordion_5 from "../../static/images/accordion_5.png";

export const accordionItems = [
    { id: 1, img: accordion_1, title: 'Профессиональная разработка под ключ', content: 'Обеспечиваем полный цикл разработки, начиная от концепции и дизайна, до запуска и сопровождения. Вам не нужно беспокоиться о технических деталях, всю работу мы берем на себя.' },
    { id: 2, img: accordion_2, title: 'Индивидуальный подход', content: 'Осознаем уникальность каждого проекта, предоставляя клиентам персонализированный подход. Изучаем ваши потребности, цели и видение проекта. Так мы разрабатываем индивидуальные решения, учитывающие особенности бизнеса и аудитории.' },
    { id: 3, img: accordion_3, title: 'Высокое качество и современный дизайн', content: 'Уделяем особое внимание качеству разработки и дизайну приложений. Наши эксперты следят за последними трендами и применяют лучшие практики, гарантируя не только надежность и высокую производительность, но и современный, привлекательный дизайн.' },
    { id: 4, img: accordion_4, title: 'Гибкие условия', content: 'Ценим ваши потребности и предлагаем гибкие условия сотрудничества. Предлагаем различные модели оплаты, адаптируемы к сложности проекта. Наша цель - создать комфортные условия взаимодействия, которые приведут к результату.' },
    { id: 5, img: accordion_5, title: 'Эффективность и результат', content: 'Ориетированы на эффективность в каждом этапе разработки, начиная от оптимизации процессов до применения передовых технологий. Вы получаете высококачественное приложение, полностью соответствующее ожиданиям и приносящее реальную ценность для бизнеса.' },
];
